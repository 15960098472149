import { Subject } from 'rxjs'
import { type TemplateResult, html } from 'lit-html'

import { BaseTemplate } from 'app/core/base-template'
import { type CookieConsent } from 'app/models/consent-data.model'
import { AppLabels } from 'app/models/app-translations.model'
import { ConsentGeneralEvent } from './consent-general.model'

export class ConsentGeneralTemplate extends BaseTemplate<CookieConsent, ConsentGeneralEvent> {
  private acceptAll$!: Subject<unknown>
  private manageOptions$!: Subject<unknown>

  constructor() {
    super()
    this.registerEvents()
  }

  getTemplate(data: CookieConsent): TemplateResult {
    const btnClass = 'c-btn'

    return html`
      <div class="consent-modal2__general-wrap">
        <div class="consent-modal2__wysiwyg" .innerHTML=${data.private_policy}></div>
      </div>

      <div class="consent-modal2__general-btn">
        <button class="c-btn" @click="${this.acceptAll.bind(this)}">
          ${this.translate(AppLabels.AcceptAllBtnLabel)}
        </button>
        <button class="${btnClass}" @click="${this.manageOptions.bind(this)}">
          ${this.translate(AppLabels.ManageOptsBtnLabel)}
        </button>
      </div>
    `
  }

  private registerEvents(): void {
    this.registerEvent(ConsentGeneralEvent.AcceptAll, (this.acceptAll$ = new Subject()))
    this.registerEvent(ConsentGeneralEvent.ManageOptions, (this.manageOptions$ = new Subject()))
  }

  private acceptAll(): void {
    this.acceptAll$.next(new Date())
  }

  private manageOptions(): void {
    this.manageOptions$.next(new Date())
  }
}
