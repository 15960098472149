import { type AppConfig } from '../models/app-config.model'
import { SupportedLanguages } from '../models/app-translations.model'

export class AppConfigService {
  private static instance: AppConfigService
  private readonly config: AppConfig = {
    apiUrl: '',
    integrateWithGTM: false,
    displayStickyTrigger: false,
    displayRejectAllButton: true,
    displayCloseButton: false,
    widgetCommand: 'cookieadapter_save_log',
    currentLang: SupportedLanguages.En,
    customModalClassName: ''
  }

  static getInstance(): AppConfigService {
    if (AppConfigService.instance === undefined) {
      AppConfigService.instance = new AppConfigService()
    }

    return AppConfigService.instance
  }

  /**
   * Public API method: set api url
   */
  setApiUrl(url: string): void {
    this.config.apiUrl = url
  }

  /**
   * Public API method: get api url
   */
  getApiUrl(): string {
    return this.config.apiUrl
  }

  /**
   * Public API method: set widget command
   */
  setWidgetCommand(command: string): void {
    this.config.widgetCommand = command
  }

  /**
   * Public API method: get widget command
   */
  getWidgetCommand(): string {
    return this.config.widgetCommand
  }

  /**
   * Public API method: set current interface lang
   */
  setInterfaceLang(lang: SupportedLanguages): void {
    this.config.currentLang = lang
  }

  /**
   * Public API method: set current interface lang
   */
  getInterfaceLang(): SupportedLanguages {
    return this.config.currentLang
  }

  /**
   * Public API method: set integration w Google Tag Manager
   */
  setIntegrationWithGTM(enable: boolean): void {
    this.config.integrateWithGTM = enable
  }

  /**
   * Public API method: get integration w Google Tag Manager state
   */
  getIntegrationWithGTM(): boolean {
    return this.config.integrateWithGTM
  }

  /**
   * Public API method: set visibility of sticky trigger button
   */
  setDisplayStickyTriggerState(visible: boolean): void {
    this.config.displayStickyTrigger = visible
  }

  /**
   * Public API method: get visibility state of sticky trigger button
   */
  getDisplayStickyTriggerState(): boolean {
    return this.config.displayStickyTrigger
  }

  /**
   * Public API method: set visibility state of reject all button
   */
  setDisplayRejectAllButtonState(visible: boolean): void {
    this.config.displayRejectAllButton = visible
  }

  /**
   * Public API method: get visibility state of reject all button
   */
  getDisplayRejectAllButtonState(): boolean {
    return this.config.displayRejectAllButton
  }

  /**
   * Public API method: set visibility state of close button
   */
  setDisplayCloseButtonState(visible: boolean): void {
    this.config.displayCloseButton = visible
  }

  /**
   * Public API method: get visibility state of display close button
   */
  getDisplayCloseButtonState(): boolean {
    return this.config.displayCloseButton
  }

  /**
   * Public API method: set custom css class form modal
   */
  setCustomCssClass(className: string): void {
    this.config.customModalClassName = className
  }

  /**
   * Public API method: get custom css class name of modal
   */
  getCustomCssClass(): string {
    return this.config.customModalClassName
  }
}
