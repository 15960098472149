import { take } from 'rxjs'
import { MainComponent } from '../components/consent-main/consent-main.component'
import { type SupportedLanguages } from '../models/app-translations.model'
import { type TranslateConfig } from '../models/translate.service.model'
import { initDocumentWriteDisable } from '../polyfills/document-write'
import { customEventPolyfill } from '../polyfills/event-polyfill'
import { initNodeListForeachPolyfill } from '../polyfills/nodelist-foreach'
import { AppConfigService } from './app-config.service'
import { DomPreparationService } from './dom-preparation.service'
import { TranslateService } from './translate.service'

export class AppInitializerService {
  private static instance: AppInitializerService
  private translateConfig!: TranslateConfig
  private readonly domPreparationService: DomPreparationService
  private readonly translateService: TranslateService
  private readonly appConfigService: AppConfigService
  private areConsentVerifiedState = false

  constructor() {
    this.domPreparationService = DomPreparationService.getInstance()
    this.translateService = TranslateService.getInstance()
    this.appConfigService = AppConfigService.getInstance()
  }

  static getInstance(): AppInitializerService {
    if (AppInitializerService.instance === undefined) {
      AppInitializerService.instance = new AppInitializerService()
    }

    return AppInitializerService.instance
  }

  setApiUrl(url: string): void {
    this.appConfigService.setApiUrl(url)
  }

  setWidgetCommand(command: string): void {
    this.appConfigService.setWidgetCommand(command)
  }

  setTranslationConfig(translateConfig: TranslateConfig): void {
    this.translateConfig = translateConfig
  }

  setInterfaceLang(lang: SupportedLanguages): void {
    this.appConfigService.setInterfaceLang(lang)
  }

  setIntegrationWithGTM(enable = false): void {
    this.appConfigService.setIntegrationWithGTM(enable)
  }

  displayStickyTrigger(visible = false): void {
    this.appConfigService.setDisplayStickyTriggerState(visible)
  }

  displayRejectAllButton(visible = true): void {
    this.appConfigService.setDisplayRejectAllButtonState(visible)
  }

  displayCloseButton(visible = false): void {
    this.appConfigService.setDisplayCloseButtonState(visible)
  }

  addCustomCssClass(className: string): void {
    this.appConfigService.setCustomCssClass(className)
  }

  init(): void {
    this.initTranslateService()
    this.initMainApp()
  }

  runAllNodes(): void {
    this.domPreparationService.runMainScript()
    this.domPreparationService.runStrictElements()
    this.domPreparationService.runAllElements()
  }

  areConsentsVerified(): boolean {
    return this.areConsentVerifiedState
  }

  private loadPolyfills(): void {
    initDocumentWriteDisable()
    initNodeListForeachPolyfill()
    customEventPolyfill()
  }

  private initTranslateService(): void {
    this.translateService.init(this.translateConfig)
  }

  private checkIfConsentsNeed(): boolean {
    const widget: HTMLElement | null = document.querySelector('[data-component="cookie-consent-app2"]')

    return Boolean(widget)
  }

  private initMainApp(): void {
    this.areConsentVerifiedState = this.checkIfConsentsNeed()

    this.domPreparationService.runMainScript().subscribe(() => {
      if (this.checkIfConsentsNeed()) {
        this.translateService.ready$.pipe(take(1)).subscribe(() => {
          this.loadPolyfills()
          this.domPreparationService.runStrictElements()
          const main = new MainComponent()
          main.initApp()
        })
      } else {
        this.domPreparationService.runStrictElements()
        this.domPreparationService.runAllElements()
      }
    })
  }
}
