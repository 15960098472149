import { type TemplateResult, html, nothing } from 'lit-html'
import { Subject } from 'rxjs'

import { BaseTemplate } from 'app/core/base-template'
import { AppLabels } from 'app/models/app-translations.model'
import { ConsentWrapperEvent } from './consent-wrapper.model'
import { ConsentWrapperStage } from 'app/models/consent-wrapper.model'

interface TemplateData {
  elements: HTMLElement[]
  stage: ConsentWrapperStage
  options: {
    showRejectAllBtn: boolean
  }
}

export class ConsentWrapperTemplate extends BaseTemplate<TemplateData, ConsentWrapperEvent> {
  private acceptAll$!: Subject<unknown>
  private saveConsents$!: Subject<unknown>
  private rejectAll$!: Subject<unknown>

  constructor() {
    super()
    this.registerEvents()
  }

  getTemplate(data: TemplateData): TemplateResult {
    switch (data.stage) {
      case ConsentWrapperStage.Details:
        return html`
          <div class="consent-modal2__details-wrapper">
            ${data.elements.map(element => html`${element}`)} ${this.getButtonsTemplate(data.options.showRejectAllBtn)}
          </div>
        `
      case ConsentWrapperStage.General:
        return html`${data.elements.map(element => html`${element}`)}`
    }
  }

  private getButtonsTemplate(showRejectAllBtn: boolean): TemplateResult {
    const btnClass = 'c-btn'

    return html`
      <div class="consent-modal2__details-btn">
        <button class="c-btn" @click="${this.emitAcceptAll.bind(this)}">
          ${this.translate(AppLabels.AcceptAllBtnLabel)}
        </button>
        <button class="${btnClass}" @click="${this.emitSaveConsents.bind(this)}">
          ${this.translate(AppLabels.SaveConsentBtnLabel)}
        </button>

        ${showRejectAllBtn
          ? html`<button class="${btnClass}" @click="${this.emitSetAsRejectedAllConsents.bind(this)}">
              ${this.translate(AppLabels.RejectAllBtnLabel)}
            </button>`
          : nothing}
      </div>
    `
  }

  private registerEvents(): void {
    this.registerEvent(ConsentWrapperEvent.AcceptAll, (this.acceptAll$ = new Subject()))
    this.registerEvent(ConsentWrapperEvent.SaveConsents, (this.saveConsents$ = new Subject()))
    this.registerEvent(ConsentWrapperEvent.RejectAll, (this.rejectAll$ = new Subject()))
  }

  private emitSaveConsents(): void {
    this.saveConsents$.next(new Date())
  }

  private emitSetAsRejectedAllConsents(): void {
    this.rejectAll$.next(new Date())
  }

  private emitAcceptAll(): void {
    this.acceptAll$.next(new Date())
  }
}
